<template>
    <div>
        <overhaul-apply
            v-if="overhaulApplyVisible"
            ref="ref_overhaulApply"
            @closeForm="closeForm"></overhaul-apply>
        <overhaul-receive
            v-if="overhaulReceiveVisible"
            ref="ref_overhaulReceive"
            @closeForm="closeForm"></overhaul-receive>
        <put-into-production-apply
            v-if="putIntoProductionApplyVisible"
            ref="ref_putIntoProductionApply"
            @closeForm="closeForm"></put-into-production-apply>
        <put-into-production-receive
            v-if="putIntoProductionReceiveVisible"
            ref="ref_putIntoProductionReceive"
            @closeForm="closeForm"></put-into-production-receive>
        <reconnaissance
            v-if="reconnaissanceVisible"
            ref="ref_reconnaissance"
            :id="currentData.businessFormId"
            :workflowTaskId="currentData.id"
            :taskStatus="currentData.taskStatus"
            @closePanel="closeForm"
             @clickStep="clickStep"
        ></reconnaissance>
        <flaw-task v-if="flawTaskVisible" ref="ref_flawTask" @closeForm="closeForm"></flaw-task>
        <flaw-task-accept v-if="flawTaskAcceptVisible" ref="ref_flawTaskAccept" @closeForm="closeForm"></flaw-task-accept>
        <flaw-task-completion v-if="flawTaskCompletionVisible" ref="ref_flawTaskCompletion" @closeForm="closeForm"></flaw-task-completion>
    </div>
</template>

<script>
import Reconnaissance from '../../reconnaissance/reconnaissanceForm';
import OverhaulApply from '../../overhaul/overhaulCreate';
import OverhaulReceive from '../../overhaul/overhaulReceive';
import FlawTask from '../../emergencyRepair/flawTaskForm';
import FlawTaskAccept from '../../emergencyRepair/flawTaskAcceptForm';
import FlawTaskCompletion from '../../emergencyRepair/flawTaskCompletionForm';
import PutIntoProductionApply from '../../putIntoProduction/putIntoProductionCreate';
import PutIntoProductionReceive from '../../putIntoProduction/putIntoProductionReceive';

export default {
    components: {
        Reconnaissance,
        OverhaulApply,
        OverhaulReceive,
        FlawTask,
        FlawTaskAccept,
        FlawTaskCompletion,
        PutIntoProductionApply,
        PutIntoProductionReceive,
    },
    data() {
        return {
            currentData: {},
            businessApplyVisible: false,
            flowDrawingApplyVisible: false,
            reconnaissanceVisible: false,
            electricPlanVisible: false,
            completionApplyVisible: false,
            completionAcceptanceVisible: false,
            datumArchiveVisible: false,
            overhaulApplyVisible: false,
            overhaulReceiveVisible: false,
            emergencyRepairVisible: false,
            flawTaskVisible: false,
            flawTaskAcceptVisible: false,
            flawTaskCompletionVisible: false,
            putIntoProductionApplyVisible: false,
            putIntoProductionReceiveVisible: false,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let handleType = this.$route.query.handleType;
            if (handleType && handleType === 'create') {
                this.create(this.$route.query);
            }
            if (handleType && handleType === 'handle') {
                this.handle(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            }
        },
        create(item) {
            this.overhaulApplyVisible = true;
            item.type = 1;
            this.$nextTick(() => {
                this.$refs.ref_overhaulApply.init(item);
            });
        },
        edit(item) {
            this.currentData = item;
            this.overhaulApplyVisible = true;
            this.$nextTick(() => {
                this.$refs.ref_overhaulApply.init(item);
            });
        },
        handle(item) {
            switch (item.nextNode.code) {
                case 'elec_overhaul_planned_apply':
                    this.overhaulApplyVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulApply.init(item);
                    });
                    break;
                case 'elec_overhaul_planned_receipt':
                    this.overhaulReceiveVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_overhaulReceive.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        handelEmergencyRepair(item) {
            switch (item.formKey) {
                case 'form_flaw_task':
                    this.flawTaskVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTask.init(item);
                    });
                    break;
                case 'form_flaw_task_accept':
                    this.flawTaskAcceptVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTaskAccept.init(item);
                    });
                    break;
                case 'form_flaw_task_completion':
                    this.flawTaskCompletionVisible = true;
                    this.$nextTick(() => {
                        this.$refs.ref_flawTaskCompletion.init(item);
                    });
                    break;
                default:
                    break;
            }
        },
        info(item) {},
        /*closeForm(type) {
            this.$emit('closeForm', type);
        },*/
        closeForm() {
            //this.$store.dispatch('delVisitedViews', this.$route);
            // this.$router.back();
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        },
    }
};
</script>

