<template>
    <div>
        <div class="form-table">
            <flow-overhaul-approve
                v-if="showOverhaul"
                ref="ref_overhaul"
                @closePanel="closeForm"
            ></flow-overhaul-approve>
            <!--通用审核组件,默认不显示-->
            <div v-if="showReviewForm">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>审核情况</span>
                    </div>
                    <el-form
                        label-position="top"
                        :model="reviewForm"
                        ref="reviewForm"
                        @keyup.enter.native="reviewFormSubmit()"
                        label-width="120px"
                    >
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="审核意见" prop="reviewNote">
                                    <el-input
                                        type="textarea"
                                        v-model="reviewForm.reviewNote"
                                        placeholder="审核意见"
                                        :row="5"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" v-if="nextNodeCode === 'elec_overhaul_planned_first_review'">
                            <el-col :span="6">
                                <el-form-item label="选择项目负责人">
                                    <el-select v-model="nextUsername" filterable placeholder="请选择">
                                        <el-option
                                            v-for="item in userList"
                                            :key="item.username"
                                            :value="item.username"
                                            :label="item.realName"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
                    <el-table
                        :data="rejectNodesList"
                        border
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        style="width: 100%"
                        height="250"
                    >
                        <el-table-column
                            align="center"
                            fixed
                            prop="name"
                            :show-overflow-tooltip="true"
                            label="步骤名称"
                        >
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center; margin-top: 10px">
                        <el-button type="primary" @click="rejectSubmit">驳回</el-button>
                    </div>
                </el-dialog>
            </div>
            <record-history v-if="showRecordHistory" :instance="data" />
        </div>
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
            <el-button type="danger" @click="showRejectDialog">驳回</el-button>
            <el-button
                v-if="nextNodeCode !== 'elec_overhaul_planned_cancellation'"
                type="primary"
                @click="reviewFormSubmit(1)"
                >通过</el-button
            >
            <!--<el-button v-if="nextNodeCode === 'elec_overhaul_planned_fourth_review'" type="danger" @click ="cancellation()">作废</el-button>
        <el-button v-if="nextNodeCode === 'elec_overhaul_planned_cancellation'" type="danger" @click ="ratifyCancellation()">同意作废</el-button>-->
        </div>
    </div>
</template>

<script>
/**
 * 通用审核组件
 */
import FlowOverhaulApprove from '@/pages/overhaul/overhaulDetail';
import RecordHistory from '@/components/workflow/RecordHistory';

export default {
    components: {
        FlowOverhaulApprove,
        RecordHistory,
    },
    data() {
        return {
            showFlowDrawingApplyInfo: false,
            showElectricPlanInfo: false,
            showFlawTaskAcceptInfo: false,
            showFlawTaskCompletionInfo: false,
            showReviewForm: false,
            showOverhaul: false,
            showRecordHistory: false,
            showPutIntoProduction: false,
            workflowTaskId: 0,
            roleList: [], //检修相关角色
            reviewForm: {
                rejectNext: '', //驳回至哪一步
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            rejectNodesList: [],
            extraProps: [],
            showRejectBox: true,
            rejectCallback: null,
            dialogVisible: false,
            data: {},
            currentRow: null,
            nextNodeCode: '', //下环节code
            userList: [], //下拉选择下环节人
            nextUsername: '',
            nextRoles: [], //下环节处理角色
            nextUsers: [], //下环节处理人
            records: [], //审核记录
        };
    },
    created() {
        this.init(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
    },
    methods: {
        /* ratifyCancellation() {
            this.$confirm('确定同意作废该项工作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let ids = [];
                ids.push(this.data.id);
                this.$client.workflowInstanceProcess({
                    definitionCode: 'elec_overhaul_planned',
                    instanceId: this.data.id,
                    nodeCode: this.data.nextNode.code,
                    operationType: 'halt',
                    data: this.reviewForm,
                    user: this.$store.state.userInfo.username,
                    roles: this.$store.state.userRole,
                    nextUsers: this.nextUsers,
                    nextRoles: this.nextRoles,
                }).then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.closeForm(2);
                });
            });
        },*/
        /*//作废工单
        cancellation() {
            if (!this.roleList) {
                return;
            }
            this.nextRoles = [];
            this.nextUsers = [];
            let roles = this.roleList;
            //待运维代维审核
            roles.forEach(item => {
                if (item.roleCode === 'SCBXMFZR') {
                    this.nextRoles.push(item.roleId);
                }
                if (item.roleCode === 'FJBXMFZR') {
                    this.nextRoles.push(item.roleId);
                }
                if (item.roleCode === 'GCGLBXMFZR') {
                    this.nextRoles.push(item.roleId);
                }
            }),
            //设置往下一步流程
            this.reviewForm.cancellation = '0';
            this.$confirm('确定作废该项工作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client.workflowInstanceProcess({
                    definitionCode: 'elec_overhaul_planned',
                    instanceId: this.data.id,
                    nodeCode: this.data.nextNode.code,
                    operationType: 'saveAndNext',
                    data: this.reviewForm,
                    user: this.$store.state.userInfo.username,
                    roles: this.$store.state.userRole,
                    nextUsers: this.nextUsers,
                    nextRoles: this.nextRoles,
                }).then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.closeForm(2);
                });
            });
        },*/
        init(item) {
            this.rejectNodesList = item.rejectNodes;
            this.data = item;
            this.records = item.records;
            this.workflowTaskId = item.id;
            this.showOverhaul = true;
            this.showReviewForm = true;
            this.showRecordHistory = true;
            this.extraProps = item.extraProps;
            this.nextNodeCode = item.nextNode.code;
            this.getRoleList();
            this.setRejectNode();
            if (this.nextNodeCode === 'elec_overhaul_planned_first_review') {
                this.getUserListByRoleCodeList();
            }
            this.$nextTick(() => {
                this.$refs.ref_overhaul.init(item);
            });
        },
        //设置rejectNode的处理人
        setRejectNode() {
            if (!this.rejectNodesList || this.rejectNodesList.length < 1) {
                return;
            }
            let records = this.records;
            this.rejectNodesList.forEach((reject) => {
                //发起环节
                if (reject.code === 'elec_overhaul_planned_apply') {
                    this.extraProps.forEach((r) => {
                        if (r.code === 'createUser') {
                            reject.handler = r.value;
                        }
                    });
                }
                //审批环节
                if (records && records.length > 0) {
                    records.forEach((r) => {
                        if (reject.id === r.node.id) {
                            reject.handler = r.handler;
                        }
                    });
                }
            });
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        showRejectDialog() {
            this.dialogVisible = true;
        },
        rejectSubmit() {
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error',
                });
                return;
            }
            this.reviewForm.rejectNext = this.currentRow.name;
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_overhaul_planned',
                        instanceId: this.data.id,
                        nodeCode: this.data.nextNode.code,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data: this.reviewForm,
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                        nextUsers: [this.currentRow.handler],
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                        });
                        this.closeForm(2);
                    });
            });
        },
        reviewFormSubmit(reviewStatus) {
            this.reviewForm.reviewStatus = reviewStatus;
            this.setNextUserAndRole();
            if (
                this.nextNodeCode === 'elec_overhaul_planned_first_review' &&
                (!this.nextUsers || this.nextUsers.length < 1 || !this.nextUsers[0])
            ) {
                this.$message({
                    message: '请选择下一步操作人',
                    type: 'error',
                });
                return;
            }
            this.reviewForm.reviewBy = this.$store.state.userInfo.realName;
            this.$client
                .workflowInstanceProcess({
                    definitionCode: 'elec_overhaul_planned',
                    instanceId: this.data.id,
                    nodeCode: this.data.nextNode.code,
                    operationType: 'saveAndNext',
                    data: this.reviewForm,
                    user: this.$store.state.userInfo.username,
                    roles: this.$store.state.userRole,
                    nextUsers: this.nextUsers,
                    nextRoles: this.nextRoles,
                })
                .then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    });
                    this.closeForm(2);
                });
        },
        closeForm(type) {
            //this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
            this.$root.$emit('elec_overhaul_planned_refresh');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        },
        //获取检修相关角色
        getRoleList() {
            //判断当前登录的用户是发计部还是市场部
            let userRoleCode = this.$store.state.userInfo.roleCodeList;
            let unitType = '';
            if (userRoleCode && userRoleCode.indexOf('SCB') > -1) {
                unitType = 'SCB';
            }
            if (userRoleCode && userRoleCode.indexOf('FJB') > -1) {
                unitType = 'FJB';
            }
            if (userRoleCode && userRoleCode.indexOf('GCGLB') > -1) {
                unitType = 'GCGLB';
            }
            if (userRoleCode && userRoleCode.indexOf('KZYW') > -1) {
                unitType = 'FJB';
            }
            this.$client.queryOverhaulRoleList(unitType).then((data) => {
                this.roleList = data.data;
            });
        },
        //根据角色code查询用户
        getUserListByRoleCodeList() {
            let codeList = [];
            let userInfo = this.$store.state.userInfo.roleCodeList;
            userInfo.forEach((item) => {
                if (item === 'SCBXMJBR') {
                    codeList.push('SCBXMFZR');
                }
                if (item === 'FJBXMJBR') {
                    codeList.push('FJBXMFZR');
                }
                if (item === 'GCGLBXMJBR') {
                    codeList.push('GCGLBXMFZR');
                }
            });
            this.$client.queryUserByRoleCodeList(codeList).then((data) => {
                this.userList = data.data;
            });
        },
        //获取下一环节角色
        setNextUserAndRole() {
            if (!this.roleList) {
                return;
            }
            this.nextRoles = [];
            this.nextUsers = [];
            let roles = this.roleList;
            switch (this.nextNodeCode) {
                case 'elec_overhaul_planned_first_review':
                    this.nextUsers.push(this.nextUsername);
                    break;
                case 'elec_overhaul_planned_second_review':
                    //待运维代维审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'SCBYWDWZBY') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'FJBYWDWZBY') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'GCGLBYWDWZBY') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_overhaul_planned_third_review':
                    //待调度经办人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'SCBDDJBR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'FJBDDJBR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'GCGLBDDJBR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_overhaul_planned_fourth_review':
                    //待发计部负责人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'SCBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'FJBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'GCGLBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_overhaul_planned_fifth_review':
                    //第二阶段选择的经办人 JHJXHZR
                    roles.forEach((item) => {
                        if (item.roleCode === 'SCBHZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'FJBHZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'GCGLBHZR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_overhaul_planned_receipt':
                    //发计部负责人
                    roles.forEach((item) => {
                        if (item.roleCode === 'SCBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'FJBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                        if (item.roleCode === 'GCGLBDDFZR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-textarea__inner {
    color: white;
}
.form-table {
    height: calc(100vh - 170px);
    margin-bottom: 10px;
    overflow-y: scroll;
}
.button-fixed {
    margin: 0 !important;
}
.submit-btn-group {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
</style>
