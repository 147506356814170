<template>
    <div class="planned ui-height-100">
        <div class="ui-layout-container ui-width-100 workflow-list__wrap ui-height-100">
            <div class="workflow-list ui-height-100 ui-layout-flex ui-layout-flex--column" v-if="flowPanel">
                <div class="workflow-list__header">
                    <div class="workflow-list__navbar">
                        <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="all">所有业务</el-menu-item>
                            <el-menu-item index="selfStart">我发起的</el-menu-item>
                            <el-menu-item index="pending">
                                待我处理
                                <!-- <span class="workflow-list__notice-count">10</span> -->
                            </el-menu-item>
                            <el-menu-item index="processed">已处理的</el-menu-item>
                            <el-menu-item index="finished">已归档</el-menu-item>
                        </el-menu>
                    </div>
                    <el-row :gutter="24" class="workflow-list__nav-form" style="margin: 6px 0px 0px 0px">
                        <el-col :span="4">
                            <div class="">
                                <el-select
                                    v-model="dataForm['extraProp.nextNodeCode']"
                                    :clearable="true"
                                    placeholder="请选择状态"
                                >
                                    <el-option
                                        v-for="item in overhaulstatusList"
                                        :key="item.itemKey"
                                        :label="item.itemValue"
                                        :value="item.itemKey"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="">
                                <el-input
                                    v-model="dataForm['extraProp.ticketNo']"
                                    placeholder="请输入工作单号"
                                    clearable
                                ></el-input>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div>
                                <el-button
                                    @click="search"
                                    type="primary"
                                    icon="el-icon-search"
                                    style="border-radius: 13px"
                                    >搜索</el-button
                                >
                                <el-button
                                    @click="refresh"
                                    type="primary"
                                    icon="el-icon-refresh"
                                    style="border-radius: 13px"
                                    >重置</el-button
                                >
                                <!--<el-button @click="handleCommand(flows)"
                                       type="primary"
                                       icon="el-icon-plus"
                                       style="border-radius: 4px"
                            >新建
                            </el-button>-->
                            </div>
                        </el-col>
                        <el-col :span="12" style="text-align: right" v-if="canAdd">
                            <el-button
                                @click="handleCommand(flows)"
                                type="primary"
                                icon="el-icon-plus"
                                style="border-radius: 4px"
                                >新建
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="ui-layout-flex--1 ui-width-100" style="overflow-y: auto;">
                    <el-table
                        ref="table"
                        :data="dataList"
                        border
                        style="width: 100%;"
                    >
                        <el-table-column
                            align="center"
                            fixed
                            prop="record_apply.data.ticketNo"
                            :show-overflow-tooltip="true"
                            label="任务工单号"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            fixed
                            prop="startTime"
                            :formatter="dateFormat"
                            :show-overflow-tooltip="true"
                            label="提交时间"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="record_apply.data.chargeName"
                            :show-overflow-tooltip="true"
                            label="工作负责人"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="record_apply.data.chargeTel"
                            :show-overflow-tooltip="true"
                            label="负责人联系电话"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="record_apply.data.equipmentName"
                            :show-overflow-tooltip="true"
                            label="停电设备"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="record_apply.data.cutBeginTime"
                            :formatter="dateFormatCTS"
                            :show-overflow-tooltip="true"
                            label="申请停电开始时间"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="record_apply.data.cutEndTime"
                            :formatter="dateFormatCTS"
                            :show-overflow-tooltip="true"
                            label="停电结束时间"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="status"
                            :formatter="getStatus"
                            :show-overflow-tooltip="true"
                            label="状态"
                        >
                        </el-table-column>
                        <el-table-column align="center" fixed="right" :show-overflow-tooltip="true" label="操作">
                            <template slot-scope="scope">
                                <div v-if="type != 'pending'">
                                    <el-button
                                        type="info"
                                        size="small"
                                        @click="openInfo(scope.row)"
                                        class="workflow-list__action-btn"
                                        >详情
                                    </el-button>
                                </div>
                                <div v-else-if="type == 'pending'">
                                    <el-button
                                        type="info"
                                        size="small"
                                        @click="handelFlow(scope.row)"
                                        class="workflow-list__action-btn"
                                        >处理
                                    </el-button>
                                </div>
                                <!--//v-if="nextNodeCode === 'elec_overhaul_planned_fourth_review'"
                                //v-if="nextNodeCode === 'elec_overhaul_planned_cancellation'"-->
                                <!--<div>
                                    <el-button type="danger" @click ="cancellation()">作废</el-button>
                                </div>-->
                                <div v-if="deletebutton(scope.row)">
                                    <el-button
                                        type="info"
                                        size="small"
                                        class="workflow-list__action-btn"
                                        @click="deleteForm(scope.row)"
                                        >删除
                                    </el-button>
                                </div>
                                <!--<div v-if="ratifyCancellationShow(scope.row)">
                                    <el-button class="workflow-list__action-btn" type="info" size="small" @click ="ratifyCancellation(scope.row)">作废</el-button>
                                </div>-->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="workflow-list__foot">
                    <el-row :gutter="24">
                        <el-col :span="6" :offset="18" style="margin-top: 10px">
                            <el-pagination
                                style="text-align: center"
                                @current-change="handleCurrentChange"
                                :current-page="pageData.pageNumber"
                                :page-size="pageData.pageSize"
                                background
                                layout="total, prev, pager, next, jumper"
                                :total="total"
                            ></el-pagination>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="load-workflow-step" v-else>
                <template v-if="showStepInfo">
                    <business-apply-show
                        v-if="showStepIndex === 0"
                        :id="currentRow.businessFormId"
                        @closePanel="closePanel"
                    ></business-apply-show>
                    <reconnaissance-show
                        v-if="showStepIndex === 1"
                        :id="currentRow.businessFormId"
                        @closePanel="closePanel"
                    ></reconnaissance-show>
                    <electric-plan-show
                        v-if="showStepIndex === 2"
                        :id="currentRow.businessFormId"
                        @closePanel="closePanel"
                    ></electric-plan-show>
                </template>
                <div v-show="!showStepInfo">
                    <review v-if="showReview" ref="review_win" @closeForm="closeForm" @clickStep="clickStep"></review>
                    <handle v-if="showHandel" ref="handel_win" @closeForm="closeForm" @clickStep="clickStep"></handle>
                    <info v-if="showInfo" ref="info_win" @closeForm="closeForm" @clickStep="clickStep"></info>
                    <notice v-if="showNotice" ref="notice_win" @closeForm="closeForm" @clickStep="clickStep"></notice>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Handle from './handle';
import Review from './review';
import Info from './info';
import Notice from './notice';
import BusinessApplyShow from '../../businessApply/businessApplyShow';
import ReconnaissanceShow from '../../reconnaissance/reconnaissanceShow';
import ElectricPlanShow from '../../electricPlan/electricPlanShow';
import * as dayjs from 'dayjs';
export default {
    name: 'planned',
    components: {
        Handle,
        Review,
        Info,
        Notice,
        BusinessApplyShow,
        ReconnaissanceShow,
        ElectricPlanShow,
    },
    data() {
        return {
            flowApplyID: 0,
            flowPanel: true,
            flowAction: 0,
            flowList: [],
            flows: new Map(),
            dataList: [],
            flowGroupMap: [],
            /*修改*/
            overhaulstatusList: [],
            putIntoProductionStatusList: [],
            type: 'pending',
            showCreateFlow: false,
            showHandel: false,
            showNotice: false,
            showInfo: false,
            total: 0,
            showReview: false,
            canAdd: false, //是否有权限新增
            pageData: {
                pageNumber: 1,
                pageSize: 10,
            },
            dataForm: {
                definitionCode: 'elec_overhaul_planned',
                extraProp: {},
                currentStatus: '',
            },
            showStepInfo: false,
            showStepIndex: 0,
            currentRow: {},
        };
    },
    filters: {
        filterFlowGroup(val) {
            return this.flowGroupMap[val];
        },
    },
    mounted() {
        this.$root.$on('elec_overhaul_planned_refresh', this.getData);
    },
    methods: {
        getStatus(row, column, val) {
            if (row.nextNode) {
                return row.nextNode.name;
            }
            if (!row.nextNode && row.status === 'halted') {
                return '已作废';
            }
            return '已执行';
        },
        dateFormatCTS(row, column, cellValue) {
            /*if ((column.property === 'record_apply.data.workBeginTime') || 'record_apply.data.workEndTime') {
                return dayjs(row[column.property]).format('YYYY-MM-DD HH:mm');
            }
            return row[column.property];*/
            if (cellValue) {
                let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                return time;
            }
            return '';
        },
        dateFormat(row, column) {
            if (column.property === 'startTime') {
                return row[column.property].substring(0, 10);
            }
            return row[column.property];
        },
        init() {
            this.dataForm.currentStatus = '';
        },
        state(index) {
            this.init();
            if (index === 'flow_overhaul') {
                this.statusList = this.overhaulstatusList;
            } else {
                this.statusList = [];
            }
        },
        createFlow(row) {
            this.$nextTick(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_overhaul_planned',
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                    })
                    .then((resp) => {
                        this.$router.push({
                            path: '/dispatchingManagement/planned/handle',
                            query: {
                                id: resp.data.instance.id,
                                nextNode: resp.data.nextNode,
                                definitionCode: 'elec_overhaul_planned',
                                handleType: 'create',
                            },
                        });
                    });
            });
        },
        editFlow(item) {
            this.showHandel = true;
            this.flowPanel = false;
            this.$nextTick(() => {
                this.$refs.handel_win.edit(item);
            });
        },
        handleCurrentChange(val) {
            this.pageData.pageNumber = val;
            this.filterGetData();
        },
        async getListRole() {
            //判断当前登录的用户是发计部还是市场部
            let userRoleCode = this.$store.state.userInfo.roleCodeList;
            let unitType = '';
            if (userRoleCode && userRoleCode.indexOf('SCB') > -1) {
                unitType = 'SCB';
            }
            if (userRoleCode && userRoleCode.indexOf('FJB') > -1) {
                unitType = 'FJB';
            }
            if (userRoleCode && userRoleCode.indexOf('FJB') > -1) {
                unitType = 'GCGLB';
            }
            this.$client.queryOverhaulRoleList(unitType).then((res) => {
                this.listRole = res.data;
            });
        },
        async getData(extra = null) {
            let page = {
                ...this.pageData,
                ...this.dataForm,
                type: this.type,
                user: this.$store.state.userInfo.username,
                roles: this.$store.state.userRole,
            };
            if (extra) {
                page.type = extra.type;
                page.status = extra.status;
            }
            this.$client.workflowInstanceQuery(page).then((res) => {
                const recordIds = [];
                res.data.items.forEach((i) => {
                    i.records.forEach((r) => {
                        recordIds.push(r.id);
                    });
                });
                this.$client.workflowInstanceQueryRecords({ids: recordIds, pageSize: 99999}).then((resp) => {
                    this.dataList = res.data.items.map((e) => {
                        if (e.businessFormData == null) {
                            e.businessFormData = {};
                        }
                        e.records.forEach((r) => {
                            let data = resp.data.items.filter((i) => i.id === r.id)[0];

                            if (r.node.code === 'elec_overhaul_planned_apply') {
                                e.record_apply = r;
                            }
                            if (r.node.code === 'elec_overhaul_planned_receipt') {
                                e.record_receipt = r;
                            }
                            r.data = data.data;
                        });
                        return e;
                    });
                    this.dataList = this.dataList.sort((a, b) => b.record_apply.data.ticketNo > a.record_apply.data.ticketNo ? 1 : -1);
                    this.total = res.data.totalItemCount;
                    this.loading = false;
                });
            });
        },

        search() {
            this.getData();
        },
        getRoleFlow() {
            this.$client.getRoleFlow().then((res) => {
                this.flowList = res.list;
                let map = new Map();
                for (let item of res.list) {
                    let group = map.get(item.flowGroup);
                    if (item.businessFormKey === 'form_overhaul') {
                        group = [];
                        group.push(item);
                    }
                    map.set(item.flowGroup, group);
                }
                this.flows = map;
            });
        },
        refresh() {
            delete this.dataForm['extraProp.nextNodeCode'];
            delete this.dataForm['extraProp.ticketNo'];
            this.dataForm.key = '';
            this.dataForm.currentStatus = '';
            this.search();
        },
        handleCommand(v) {
            let c = v.get(10)[0];
            this.createFlow(c);
        },
        closeForm() {
            //恢复初始状态
            this.flowPanel = true;
            this.showHandel = false;
            this.showInfo = false;
            this.showReview = false;
            this.showNotice = false;
            this.getData();
        },
        showCreateWin() {
            this.flows;
            this.showCreateFlow = true;
        },
        handleSelect(key) {
            this.type = key;
            this.filterGetData();
        },
        filterGetData(){
            if (this.type === 'finished') {
                let extra = {
                    type: 'all',
                    status: 'finished',
                };
                this.getData(extra);
            } else {
                this.getData();
            }
        },
        openInfo(row) {
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/dispatchingManagement/planned/info',
                    query: {
                        id: guid,
                        handleType: 'info',
                    },
                });
            });
        },
        /*handelFlow(row) {
            this.currentRow = row;
            this.flowApplyID = row.id;
            switch (row.nextNode.code) {
                case 'elec_overhaul_planned_apply':
                case 'elec_overhaul_planned_receipt':
                    this.showHandel = true;
                    this.flowAction = 1;
                    this.flowPanel = false;
                    this.$nextTick(() => {
                        this.$refs.handel_win.handle(row);
                    });
                    break;
                case 'elec_overhaul_planned_first_review':
                case 'elec_overhaul_planned_second_review':
                case 'elec_overhaul_planned_third_review':
                case 'elec_overhaul_planned_fourth_review':
                case 'elec_overhaul_planned_fifth_review':
                    this.showReview = true;
                    this.flowAction = 2;
                    this.flowPanel = false;
                    this.$nextTick(() => {
                        this.$refs.review_win.init(row);
                    });
                    break;
                default:
                    break;
            }
        },*/
        handelFlow(row) {
            this.currentRow = row;
            this.flowApplyID = row.id;
            switch (row.nextNode.code) {
                case 'elec_overhaul_planned_apply':
                case 'elec_overhaul_planned_receipt':
                    /* this.showHandel = true;
                    this.flowAction = 1;
                    this.flowPanel = false;*/
                    this.$nextTick(() => {
                        let guid = this.guid();
                        sessionStorage.setItem(guid, JSON.stringify(row));
                        this.$router.push({
                            path: '/dispatchingManagement/planned/handle',
                            query: {
                                id: guid,
                                handleType: 'handle',
                            },
                        });
                    });
                    break;
                case 'elec_overhaul_planned_first_review':
                case 'elec_overhaul_planned_second_review':
                case 'elec_overhaul_planned_third_review':
                case 'elec_overhaul_planned_fourth_review':
                case 'elec_overhaul_planned_fifth_review':
                case 'elec_overhaul_planned_cancellation':
                    /*this.showReview = true;
                    this.flowAction = 2;
                    this.flowPanel = false;*/
                    this.$nextTick(() => {
                        //this.$refs.review_win.init(row);
                        let guid = this.guid();
                        sessionStorage.setItem(guid, JSON.stringify(row));
                        this.$router.push({
                            path: '/dispatchingManagement/planned/review',
                            query: {
                                id: guid,
                                handleType: 'handle',
                            },
                        });
                    });
                    break;
                default:
                    break;
            }
        },
        getTxt(value) {
            if (value && this.flowGroupMap) {
                return this.flowGroupMap.filter((n) => n.itemKey === value + '')[0].itemValue;
            }
            return '';
        },
        async getInit() {
            this.getRoleFlow();

            // const data = await Promise.all([
            //     this.$client.getDicData('flow_group'),
            //     this.getRoleFlow(),
            //     this.$client.getDicData('business_status'),
            // ]);
            // this.flowGroupMap = data[0].data || [];
            // //this.flowList = data[1].list || [];
            // this.statusList = data[2].data || [];
        },
        async initFormValue() {
            await this.getData();
            await this.getInit();
        },
        closePanel() {
            this.showStepInfo = false;
        },
        clickStep(index) {
            this.showStepIndex = index;
            this.showStepInfo = true;
        },
        initHandle() {
            let {act, obj} = this.$route.params;
            if (act) {
                switch (act) {
                    case 1:
                        //业扩变更
                        this.editFlow(obj);
                        break;
                }
            }
        },
        //是否有新增权限
        setAddBtn() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList && user.roleCodeList.indexOf('SCBSQTBR') > -1) {
                this.canAdd = true;
            }
            if (user && user.roleCodeList && user.roleCodeList.indexOf('FJBSQTBR') > -1) {
                this.canAdd = true;
            }
            if (user && user.roleCodeList && user.roleCodeList.indexOf('GCGLBSQTBR') > -1) {
                this.canAdd = true;
            }
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        deletebutton(row) {
            if (this.type === 'pending' && row && row.nextNode?.code === 'elec_overhaul_planned_apply') {
                let createUser = row.extraProps.filter((item) => item.code === 'createUser');
                let curUser = this.$store.state.userInfo.username;
                if (createUser && createUser.length > 0 && createUser[0].value === curUser) {
                    return true;
                }
            }
            return false;
        },
        deleteForm(row) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (this.deletebutton(row)) {
                    let ids = [row.id];
                    this.$client.workflowDelete(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000,
                            });
                        }
                        this.getData();
                    });
                } else {
                    this.$message({
                        message: '你没有权限删除',
                        type: 'error',
                    });
                    return;
                }
            });
        },
    },
    activated() {
        this.$refs.table.doLayout();
        console.log(6546545645664654654);
    },
    created() {
        this.initFormValue();
        this.initHandle();
        this.setAddBtn();
        this.$client.getDicDataArr(['elec_overhaul_planned']).then((res) => {
            this.overhaulstatusList = res.data.elec_overhaul_planned;
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.planned {
    .grid-content {
        .el-select--mini,
        .el-input--mini {
            width: 150px;
            margin-right: 10px;
        }
    }
    .def-page-foot1 {
        .el-pagination {
            position: absolute;
            top: calc(50% + 10px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .load-workflow-step {
        background-color: @bg-conent;
        height: 100%;
        overflow: hidden scroll;
        // /deep/ .el-card{
        //     background-color: @bg-conent;
        // }
        /deep/ .card-pub-notice {
            background-color: @bg-conent;
            margin-top: 10px;
            .card-pub-notice {
                background-color: @bg-light;
                margin-top: 10px;
                .card-pub-notice {
                    background-color: @bg-conent;
                    margin-top: 10px;
                }
            }
        }
        /deep/ .submit-btn-group {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        /deep/ .el-card__header {
            padding-left: 10px;
        }
    }
    .workflow-list {
        // position: relative;

        &__action-btn {
            float: right;
            & + & {
                margin-left: 10px;
            }
        }
        &__notice-count {
            background-color: #ff7260;
            color: #fff;
            border-radius: 10px;
            font-size: 12px;
            padding: 0 5px;
            position: relative;
            top: -8px;
        }
        &__card-head-border {
            border-left: 3px solid @bg-btn-light;
            padding-left: 10px;
        }
        &__card-head-type {
            margin-left: 30px;
        }
        &__item-card {
            background-color: @bg-dark;
            & + & {
                margin-top: 20px;
            }
        }
        &__nav-form {
            padding: 10px;
            padding-bottom: 20px;
        }
        &__wrap {
            background-color: @bg-light;
            height: 100%;
            width: 100%;
        }
        &__content {
            height: calc(100% - 140px);
            overflow: auto;
            padding: 20px;
            box-sizing: border-box;
        }
        &__foot {
            background-color: #161d30;
            height: 50px;
        }
        &__header {
            height: 105px;
            background-color: @bg-dark;
            border-bottom: 1px solid @bg-border;
        }
        &__navbar {
            border-bottom: 1px solid @bg-border;
            padding-top: 10px;
            /deep/ .el-menu {
                background-color: transparent;
            }
            /deep/ .el-menu.el-menu--horizontal {
                height: 30px;
                border: none;
                margin-right: 0;
                margin-bottom: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item {
                height: 35px;
                line-height: 35px;
            }
            /deep/ .el-header {
                padding: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
            /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
                background-color: lighten(@bg-dark, 22%);
            }
            /deep/ .el-menu--horizontal > .el-menu-item.is-active {
                border-color: #ededfd;
            }
        }
        /deep/ .el-card__header {
            padding: 15px 20px;
        }
        /deep/ .el-card__body {
            padding: 15px 20px;
            font-size: 14px;
        }
    }
}
</style>
